<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      error: null,
      is_guest: false,
			description: '',
			location: ''
    }
  },
  watch: {
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/class_bookings/${this.bookingID}`)
      .then(response => {
        this.is_guest = response.data.client.is_guest? false : true
				this.description = response.data.class_description
				this.location = response.data.location
				this.description = this.description ? this.description : ''
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
    this.$refs.description.focus()
  },
  methods: {
    bookingParams() {
      return {
        class_booking: {
          class_description: this.description,
        }
      }
    },
    nextStep() {
      if (this.is_guest) {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'fill_name' })
      } else {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'booking_complete' })
      }
    },
    back() {
			if(location=="customer_location") {
				this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_location' })
			} else {
				this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_date' })
			}

    },
  },
  computed: {
    disableNextBtn() {
      return this.description=='' || this.description.trim().length < 50
    },
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <div class="complete-signup-header__title">
      Anything the teacher should know about you?
    </div>

    <p class="mt-4 mb-5">
      Is there anything you would like the teacher to know, it could bw anything regarding you setup, what you want to learn or more
    </p>

    <div class="new-booking__content">
      <p class="mb-3">Event details</p>
      <div class="forms__item-100">
        <div>
          <textarea
            v-model="description"
            type="textarea"
            :required="true"
            class="textarea order-capture-input border-0"
            v-bind:class="{ 'error': error || (description && description.trim().length < 50)}"
            placeholder="Anything you want the artist to know?"
            rows="9"
            ref="description"
          />
          <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>
          <span v-if="description && description.trim().length < 50" class="error-message">Minimum of 50 characters required</span>
        </div>
      </div>

      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
            rounded
            :value="bookingProgress"
            height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            class="settings-forms__btn btn primary fluid"
            @click="nextStep()"
            :disabled="disableNextBtn">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
