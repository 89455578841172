<script>
import moment from 'moment'
import * as _ from 'lodash'

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
    flatPickr
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      moment: moment,
      isLoading: false,
      dates: [new Date()],
      currentDates: [],
      available_days: [],
      location: "",
      dateConfig: {
        static: true,
        inline: true,
        mode: 'multiple',
        enable: [
          function(date) {
            return date >= new Date().setHours(0,0,0,0);
          }
        ]
      }
    }
  },
  watch: {
    available_days(newState, oldState) {
      this.dateConfig.enable = [
        function(date) {
          const day = date.getDay();
          return newState.includes(day)
        }
      ]
    }
  },
  mounted() {
    this.$axios.get(`/v3/class_bookings/${this.bookingID}`)
    .then(response => {
      this.currentDates = response.data.class_booking_dates
      this.location = response.data.location
      this.dates = response.data.class_booking_dates.map(date => date.date).join(', ')
      let days = []
      response.data.days.forEach((day) => {
        days.push(this.days(day))
      })
      this.available_days = days
    })
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  methods: {
    dateDeselected(date) {
      this.dates = this.dates.split(',').filter(selected_date => selected_date !== date).join(', ')
    },
    bookingParams() {
      let new_dates = this.dates.split(',').map(date => {
        let currentIndex = this.currentDates.findIndex(current_date => new Date(current_date.date).toDateString() === new Date(date).toDateString())
        return {
          id: currentIndex >=0 ? this.currentDates[currentIndex].id : null,
          date: date,
          start_time: new Date(new Date(date).setHours(7)),
          end_time: new Date(new Date(date).setHours(19)),
        }
      })
      let removed_dates = this.currentDates.map(date => {
        let index = this.dates.split(',').findIndex(new_date => new Date(new_date).toDateString() === new Date(date.date).toDateString())
        if (index < 0) {
          return {
            id: date.id,
            date: date.date,
            _destroy: true
          }
        }
      }).filter(item => item)
      return {
        class_booking: {
          class_booking_dates_attributes: new_dates.concat(removed_dates)
        }
      }
    },
    nextStep() {
      if(this.location=="customer_location") {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_location' })
      } else {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'fill_description' })
      }
    },
    days(day) {
      switch (day) {
        case "mon":
          return 0
          break;
        case "tues":
          return 1
          break;
        case "wed":
          return 2
          break;
        case "thurs":
          return 3
          break;
        case "fri":
          return 4
          break;
        case "sat":
          return 5
          break;
        default:
          return 6
      }
    }
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <p class="mt-4 mb-5">
      Let artist(s) know when your event is happening so that only those available will apply
    </p>

    <div v-if="dates.length > 0" class="mb-2">
      <h6 class="mb-3">Selected Event Dates</h6>
      <ul class="selected-dates-container">
        <li
          class="selected-date-item"
          v-for="(date, index) in dates.split(',')"
          :key="index"
          @click="dateDeselected(date)">
          {{ moment(date).format('DD/MM/YYYY') }}  {{ `(${moment(date).format('dddd')})` }}
          <span class="ms-auto">
            <font-awesome-icon :icon="['fas', 'circle-xmark']" style="color: #ff6161;" />
          </span>
        </li>
      </ul>
    </div>

    <div class="new-booking__content">
      <p class="mb-1">Select event date(s)</p>
      <div class="mt-3">
        <flat-pickr
          v-model="dates"
          :config="dateConfig"
          class="input order-capture-input border-0"
          placeholder="Event Dates"
          name="date"/>
      </div>

      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
            rounded
            :value="bookingProgress"
            height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
            class="settings-forms__btn btn primary fluid"
            @click="nextStep()"
            :disabled="dates.length <= 0">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>