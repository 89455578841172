<script>
import Multiselect from 'vue-multiselect'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading,
    Multiselect
  },
  props: {
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      event_type: '',
      event_types: [],
      selectedEventType: '',
      error: null
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true

    this.$axios.get(`/v3/event_types/`)
      .then(response => {
        this.event_types = response.data
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })

    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        if (response.data.event_name !== null) {
          this.event_type = this.event_types.filter((event_type) => {
            return event_type.name === response.data.event_name
          })[0]
          this.selectedEventType = this.event_type.slug
        }
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
  },
  methods: {
    selectEventType(eventType) {
      if (this.selectedEventType === eventType.slug) {
        this.event_type = null
        this.selectedEventType = null;
      } else {
        this.selectedEventType = eventType.slug;
        this.event_type = eventType
      }
    },
    bookingParams() {
      this.event_type = this.event_type.slug? this.event_type.slug : null
      return {
        booking: {
          event_type_slug: this.event_type,
        }
      }
    },
    nextStep() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_guest' })
    },
    back() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_duration' })
    },
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <p class="mt-4 mb-5">
      Let the artists know what kind of event this is
    </p>
    <div class="event_types-container">
      <p class="mb-3">Select event type</p>
      <div class="btn-group" role="group">
        <button
          v-for="(event_type, index) in event_types"
          :key="index"
          class="btns"
          :class="selectedEventType === event_type.slug ? 'selected-event-type-item' : 'event-type-item'"
          @click="selectEventType(event_type)"
          >
          {{ event_type.name }}
        </button>
      </div>
    </div>

    <div class="mt-5">
      <v-app class="w-100 mb-4">
        <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
        <v-progress-linear
          rounded
          :value="bookingProgress"
          height="8">
        </v-progress-linear>
      </v-app>

      <div class="back-next">
        <button
          class="settings-forms__btn btn secondary fluid"
          @click="back()">
          Back
        </button>
        <button
          class="settings-forms__btn btn primary fluid"
          @click="nextStep()"
          :disabled="!event_type">
          Next
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
